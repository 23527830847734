import { useAuth0 } from "@auth0/auth0-react"
import { Menu, Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { ViewGridIcon } from "@heroicons/react/solid"
import { Fragment, useContext, useEffect } from "react"
import { Toaster } from "react-hot-toast"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { Avatar } from "../components/avatar"
import DarkModeSelector from "../components/dark-mode-selector"
import { HeroIcon } from "../components/hero-icon"
import JoyModeSelector from "../components/joy-mode-selector"
import { LogoIcon } from "../components/logo-icon"
import { isAdmin } from "../components/protected-admin"
import { SeasonalEmoji } from "../components/seasonal-emoji"
import Sidebar from "../components/sidebar"
import { Snow } from "../components/snow"
import SnowModeSelector from "../components/snow-mode-selector"
import { TenantSelector } from "../components/tenant-selector"
import { UserAvatar } from "../components/user-avatar"
import { AuthorizationContext } from "../shared/authorization-provider"
import { useIndex, useShow } from "../shared/use-rest"
import Breadcrumbs from "./breadcrumbs"

const navigation = [
  { name: "Dashboard", href: "#", current: true },
  { name: "Calendar", href: "#", current: false },
  { name: "Team External References", href: "#", current: false },
  { name: "Directory", href: "#", current: false },
]
const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Layout() {
  const { logout, isLoading: isLoadingAuth0User } = useAuth0()

  const { isError: isErrorHealthStatus } = useIndex("status", null, {
    loadingTimeout: 10000,
  })
  const {
    currentUser,
    isLoading: isLoadingCurrentUser,
    practiceName,
    practiceId,
  } = useContext(AuthorizationContext)

  const { data: practice } = useShow("practices", practiceId)
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoadingCurrentUser) return
    if (!currentUser) return
    if (Object.keys(currentUser).length === 0) {
      navigate("/401")
    }
  }, [currentUser, isLoadingCurrentUser, navigate])

  useEffect(() => {
    if (isErrorHealthStatus !== undefined)
      navigate("/404", { state: JSON.stringify(isErrorHealthStatus, null, 2) })
  }, [isErrorHealthStatus, navigate])

  if (isLoadingAuth0User)
    return <div className="p-4 h-full">Authenticating...</div>

  if (isLoadingCurrentUser)
    return <div className="p-4 h-full">Authorizing...</div>

  return (
    <>
      <Toaster />
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? "fixed inset-0 z-40 overflow-y-auto" : "",
            "bg-white shadow-sm dark:shadow-none lg:static lg:overflow-y-visible",
          )
        }
      >
        {({ open }) => (
          <>
            <div className="max-w-0xl mx-auto px-4 dark:bg-gray-800 border-b dark:border-gray-700">
              <div className="relative flex justify-between">
                <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-1">
                  <Snow />
                  <div className="flex-shrink-0 flex items-center">
                    <a className="py-2" href="/">
                      <LogoIcon />
                    </a>
                    <div className="ml-2">
                      <SeasonalEmoji />
                    </div>
                  </div>
                </div>
                <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6" />
                <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
                  {/* Mobile menu button */}
                  <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Popover.Button>
                </div>
                <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                  <TenantSelector />
                  {isAdmin(currentUser) && (
                    <Link
                      title="Events"
                      to="/admin/events"
                      className="ml-5 flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-gray-800"
                    >
                      <span className="sr-only">View events</span>
                      <HeroIcon
                        icon="RssIcon"
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </Link>
                  )}
                  <Link title="Dashboard" to="/dashboard">
                    <button
                      type="button"
                      className="ml-5 mr-5 flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-gray-800"
                    >
                      <span className="sr-only">View home</span>
                      <ViewGridIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </Link>
                  {/* Waypoints Guide */}
                  <a
                    title="Waypoints Guide"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/document/d/1vubcR5mWdwg9CCqF2W8zXN6g_tuovfsObZuM81A0C6Q/edit?usp=sharing"
                  >
                    <HeroIcon
                      icon="InformationCircleIcon"
                      className="mb-0.5 h-6 w-6 text-gray-400"
                    />
                  </a>
                  {/* Profile dropdown */}
                  <Menu as="div" className="flex-shrink-0 relative ml-5">
                    <div>
                      <Menu.Button className="bg-white dark:bg-transparent cursor-pointer rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open user menu</span>
                        <UserAvatar currentUser={currentUser} />
                        {/* <Avatar className={"h-8 w-8 rounded-full"} initials={user?.given_name[0] + user?.family_name[0]} /> */}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-52 rounded-md shadow-lg dark:shadow-none bg-white text-gray-700 dark:bg-gray-800 dark:text-gray-200 ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                        <Menu.Item key={currentUser?.name}>
                          {({ active }) => (
                            <div className="cursor-default block border-b dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700 py-2 px-4 text-sm text-gray-700">
                              {currentUser?.name}
                            </div>
                          )}
                        </Menu.Item>
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-4 px-6 text-sm",
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                        <Menu.Item>
                          <DarkModeSelector />
                        </Menu.Item>
                        <Menu.Item>
                          <SnowModeSelector />
                        </Menu.Item>
                        <Menu.Item>
                          <JoyModeSelector />
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block w-full py-2 px-4 text-sm text-gray-700 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 pt-2 border-t dark:border-gray-700",
                              )}
                              onClick={() => {
                                localStorage.removeItem("practiceId")
                                logout({
                                  logoutParams: {
                                    returnTo: window.location.origin,
                                  },
                                })
                              }}
                            >
                              Logout
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    aria-current={item.current ? "page" : undefined}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 text-gray-900"
                        : "hover:bg-gray-50",
                      "block rounded-md py-2 px-3 text-base font-medium",
                    )}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="border-t border-gray-200 dark:border-gray-800 pt-4 pb-3">
                <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                  <div className="flex-shrink-0">
                    <Avatar
                      className={"h-10 w-10 rounded-full"}
                      initials="MA"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {currentUser?.name}
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      {currentUser?.email}
                    </div>
                  </div>
                </div>
                <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                  {isAdmin(currentUser) && (
                    <Link to="/admin/events">
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <span className="sr-only">View events</span>
                        <HeroIcon
                          icon="RssIcon"
                          className="ml-1 h-6 w-6"
                          aria-hidden="true"
                        />
                      </button>
                    </Link>
                  )}
                  <Link to="/dashboard">
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="sr-only">View the grid</span>
                      <ViewGridIcon
                        className="ml-1 h-6 w-6"
                        aria-hidden="true"
                      />
                    </button>
                  </Link>
                  {userNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
      {/* Static sidebar for desktop */}
      <div className="h-[calc(100vh-70px)] md:flex flex-1">
        <Sidebar />
        <div className="md:flex-row flex-grow dark:text-gray-300">
          <Breadcrumbs />
          <Outlet />
        </div>
      </div>
    </>
  )
}
