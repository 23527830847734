import { useAuth0 } from "@auth0/auth0-react"
import {
  DataGridPro,
  type GridCellParams,
  type GridColDef,
  type GridRowHeightParams,
  type MuiEvent,
} from "@mui/x-data-grid-pro"
import { titleCase } from "change-case-all"
import { Badge, ToggleSwitch, Tooltip } from "flowbite-react"
import { type MouseEvent, useCallback, useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../../tailwind.config.js"
import { BulkAssignModal } from "../../components/bulk-assign-modal.js"
import { HeroIcon } from "../../components/hero-icon.js"
import { StudyScreenStatusChip } from "../../components/patients/study-screen-status-chip.js"
import { AuthorizationContext } from "../../shared/authorization-provider.js"
import {
  dateTimeISO,
  dateTimeISOWithoutTimezone,
} from "../../shared/friendly-dates.js"
import { useUpdate } from "../../shared/use-rest.js"
import { ToggleSwitchTheme } from "../../styles/flowbite-themes"
import { ModalType } from "../patient.js"

const fullConfig = resolveConfig(tailwindConfig)

export function CalendarScreeningScreens({ screenReviews, mutatePatients }) {
  const { getAccessTokenSilently } = useAuth0()
  const [modalOpen, setModalOpen] = useState<ModalType>()
  const { currentUser, isLoading: isLoadingCurrentUser } =
    useContext(AuthorizationContext)

  const defaultWidth = 150

  const columnDefaults: Partial<GridColDef> = {
    width: defaultWidth,
    getApplyQuickFilterFn: undefined,
    display: "flex",
  }

  const columns: GridColDef[] = [
    {
      ...columnDefaults,
      field: "Continue Screen",
      headerName: "",
      width: 110,
      renderCell: (params) => renderCellContinueScreen(params),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      ...columnDefaults,
      field: "study",
      headerName: "Study",
      width: 200,
      renderCell: (params) => renderCellStudy(params),
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "study_indication",
      headerName: "Study Indication",
      width: 175,
      renderCell: (params) => renderCellIndications(params),
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "follow_up_reason",
      headerName: "Follow Up Reason",
      width: 400,
      valueGetter: valueGetterFollowUpReason,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "follow_up_date",
      headerName: "Follow Up Date",
      width: 200,
      valueGetter: valueGetterFollowUpDate,
      valueFormatter: valueFormatterFollowUpDate,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "confidence_score",
      headerName: "Confidence Score",
      width: 175,
      valueGetter: valueGetterConfidenceScore,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      ...columnDefaults,
      field: "review_status",
      headerName: "Review Status",
      width: 175,
      renderCell: (params) =>
        renderCellReviewStatuses(params, currentUser.id, mutatePatients),
      getApplyQuickFilterFn: undefined,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ]

  const customSortableColumns = [
    "updated_at",
    "created_at",
    "next_appointment",
    "dob",
  ]

  const navigate = useNavigate()

  const getRowHeight = useCallback(
    ({ model, densityFactor }: GridRowHeightParams) => {
      // Leaving this here as a reminder: Don't fight the framework :)
      // return model.screens.length * 20 * densityFactor + 20
      return "auto"
    },
    [],
  )

  return (
    <>
      {modalOpen === ModalType.BulkAssign && (
        <BulkAssignModal
          setOpen={setModalOpen}
          handleAssign={handleAssign}
          handleArchive={handleArchive}
        />
      )}
      <div className="flex h-full py-2 bg-gray-100 text-black dark:bg-gray-800 dark:text-gray-100">
        <div className="flex-auto h-auto ml-10">
          <DataGridPro
            rows={screenReviews}
            rowCount={screenReviews.length}
            columns={columns}
            hideFooter={true}
            disableDensitySelector={true}
            disableColumnFilter={false}
            getRowHeight={getRowHeight}
            initialState={{
              density: "comfortable", // contact
            }}
            autoHeight={true}
            localeText={{ toolbarColumns: "Details" }}
            onCellDoubleClick={(
              params: GridCellParams,
              event: MuiEvent<MouseEvent>,
            ) => {
              event.defaultMuiPrevented = true
              const patientPath = `/patients/${params.row.patient_id}`
              const screen = params?.row?.screen
              if (!screen?.id) {
                navigate(`${patientPath}/screens`)
                return
              }
              navigate(`${patientPath}/screens/${params?.row?.screen?.id}`)
            }}
            sx={{
              "& .MuiDataGrid-detailPanel": {
                height: "100px !important",
              },
              border: "none",
              height: "85%",
              width: "99%",
              "& .MuiDataGrid-columnHeaderTitle": {
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: "12pt",
                color: "black",
              },
              ".dark & .MuiDataGrid-columnHeaderTitle": {
                color: fullConfig.theme.colors.gray[200],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "white",
                borderBottom: "none",
              },
              ".dark & .MuiDataGrid-columnHeaders": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiDataGrid-columnHeader": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-columnHeader--moving": {
                backgroundColor: "white",
              },
              ".dark & .MuiDataGrid-columnHeader--moving": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-toolbarContainer": {
                "& .MuiButton-root": {
                  color: "white",
                },
              },
              "& .MuiButtonBase-root": {
                color: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiButtonBase-root": {
                color: fullConfig.theme.colors.gray[100],
              },
              "&": {
                display: "flex-grow",
                height: "100%",
                justifyContent: "flex-end",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: fullConfig.theme.colors.gray[100],
              },
              ".dark & .MuiDataGrid-row:hover": {
                backgroundColor: fullConfig.theme.colors.gray[600],
              },
              ".dark & .MuiDataGrid-filler": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                backgroundColor: "white",
              },
              ".dark & .MuiDataGrid-virtualScrollerContent": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiDataGrid-virtualScroller": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "white",
                borderBottomLeftRadius: ".5rem",
                borderBottomRightRadius: ".5rem",
              },
              ".dark & .MuiDataGrid-footerContainer": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGridPro-toolbarContainer": {
                justifyContent: "flex-end",
              },
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "4px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "7px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "7px",
              },
              "&.MuiDataGrid-filterFormValueInput": {
                backgroundColor: "red",
              },
            }}
            slotProps={{
              filterPanel: {
                sx: {
                  minWidth: "50vw",
                },
                filterFormProps: {
                  valueInputProps: {
                    sx: {
                      width: "inherit",
                    },
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

const renderCellContinueScreen = (params: GridCellParams) => {
  if (params?.row?.status === "reviewed") {
    return <Badge color="success">Reviewed</Badge>
  }
  return (
    <Link
      to={`/patients/${params?.row?.patient_id}/screens/${params?.row?.screen_id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        className="border border-topo-green-200 bg-transparent hover:bg-topo-green-100 dark:hover:bg-topo-green-800 hover:bg-opacity-50 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-topo-green dark:hover:text-topo-green-200 text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
        title={params?.row?.screen?.study?.abbreviation}
      >
        Screen
        <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
      </div>
    </Link>
  )
}

const renderCellStudy = (params: GridCellParams) => (
  <>
    <Link
      key={params?.row?.screen?.id}
      to={`/patients/${params.row.patient_id}/screens/${params.row.screen_id}`}
    >
      <StudyScreenStatusChip
        text={params?.row?.study?.abbreviation?.substring(0, 13)}
        status={params?.row?.screen?.status}
      />
    </Link>
  </>
)

const valueGetterConfidenceScore = (value, row, column, apiRef) => {
  return `${row?.screen?.confidence_score || ""}`
}
const valueGetterFollowUpReason = (value, row, column, apiRef) => {
  return `${row?.screen?.follow_up_reason || ""}`
}
const valueGetterFollowUpDate = (value, row, column, apiRef) => {
  return `${dateTimeISOWithoutTimezone(row?.screen?.follow_up_dt) || ""}`
}
const valueFormatterFollowUpDate = (value, row, column, apiRef) =>
  dateTimeISO(new Date(value).toJSON())

const renderCellIndications = (params: GridCellParams) => {
  return (
    <div className="flex-col w-full align-baseline">
      <span
        key={params?.row?.study?.indication}
        className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md"
      >
        {params?.row?.study?.indication}
      </span>
    </div>
  )
}

const renderCellReviewStatuses = (
  params: GridCellParams,
  currentUserId,
  mutatePatients,
) => {
  const { getAccessTokenSilently } = useAuth0()
  const statusIsReviewed = params?.row?.status === "reviewed"
  const statusToDisplay = statusIsReviewed ? "reviewed" : "unreviewed"
  const statusToUpdate = statusIsReviewed ? "unreviewed" : "reviewed"
  const badgeColor = statusIsReviewed ? "success" : "failure"

  const onToggle = () => {
    useUpdate(
      "patient_appointment_screen_reviews",
      params?.row?.id,
      { status: statusToUpdate, updated_by_user_id: currentUserId },
      getAccessTokenSilently,
    ).then(() => {
      mutatePatients()
    })
  }

  const isReviewed = statusToDisplay === "reviewed"
  return (
    <>
      <div className="flex justify-between w-full">
        <Tooltip
          placement="left"
          content="Click to toggle review status."
          className="z-50 relative"
        >
          <ToggleSwitch
            checked={isReviewed}
            onChange={() => onToggle()}
            theme={ToggleSwitchTheme}
          />
        </Tooltip>
        <span
          className={`${
            badgeColor === "success"
              ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300"
              : "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300"
          } text-xs font-medium me-2 px-2.5 py-0.2 rounded flex justify-center items-center`}
        >
          {titleCase(statusToDisplay)}
        </span>
      </div>
    </>
  )
}
