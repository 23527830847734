import { useAuth0 } from "@auth0/auth0-react"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { event } from "../../shared/event"
import { dateCompare, friendlyTimeDate } from "../../shared/friendly-dates"
import {
  useCreate,
  useDelete,
  useIndex,
  useShow,
  useUpdate,
} from "../../shared/use-rest"
import { Avatar } from "../avatar"
import { HeroIcon } from "../hero-icon"
import { UserAvatar } from "../user-avatar"
import { StudyScreenStatusChip } from "./study-screen-status-chip"

interface Props {
  patientId: string
}

export function SidebarComments(props: Props) {
  const { register, handleSubmit, reset } = useForm()
  const { currentUser, practiceId } = useContext(AuthorizationContext)
  const {
    register: registerUpdateForm,
    handleSubmit: handleSubmitUpdateForm,
    reset: resetUpdateForm,
  } = useForm()
  const { data: patientComments, mutate: patientCommentsMutate } = useIndex(
    "patient_comments",
    `patient_id=${props.patientId}`,
  )
  const { getAccessTokenSilently } = useAuth0()
  const [updatingCommentId, setUpdatingCommentId] = useState("")
  const { screenId = "" } = useParams()
  const { data: screen } = useShow("screens", screenId)

  const onSubmit = async (data) => {
    data.patient_id = props.patientId
    data.practice_id = practiceId
    if (screen) data.study_id = screen?.study?.id
    await useCreate("patient_comments", data, getAccessTokenSilently)
    event({
      eventName: "PatientCommentCreate",
      meta: {
        patient_id: props.patientId,
        screen_id: screenId,
        element: "PatientInfoSidebar",
      },
      getAccessTokenSilently,
    })
    patientCommentsMutate()
    reset()
  }

  const handleEdit = (id, body) => {
    if (updatingCommentId === id) setUpdatingCommentId("")
    else {
      setUpdatingCommentId(id)
      resetUpdateForm({ id: id, body: body })
    }
  }
  const handleDelete = async (id) => {
    await useDelete("patient_comments", id, getAccessTokenSilently)
    event({
      eventName: "PatientCommentDelete",
      meta: {
        patient_id: props.patientId,
        element: "PatientInfoSidebar",
        patient_comment_id: id,
      },
      getAccessTokenSilently,
    })
    patientCommentsMutate()
  }

  const onUpdate = async (data) => {
    await useUpdate(
      "patient_comments",
      data.id,
      { body: data.body },
      getAccessTokenSilently,
    )
    event({
      eventName: "PatientCommentUpdate",
      meta: {
        patient_id: props.patientId,
        element: "PatientInfoSidebar",
        patient_comment_id: data.id,
      },
      getAccessTokenSilently,
    })
    setUpdatingCommentId("")
    patientCommentsMutate()
  }

  return (
    <div className="p-4 mb-4 rounded-lg border border-gray-200 shadow-md text-gray-700 bg-white dark:bg-transparent dark:text-gray-200">
      <h3 className="mb-2 text-base font-bold text-gray-900 dark:text-gray-200">
        Comments
      </h3>
      {/* New Comment */}
      <div className="flex items-center">
        <UserAvatar currentUser={currentUser} />
        <form className="flex ml-2" onSubmit={handleSubmit(onSubmit)}>
          <textarea
            {...register("body")}
            rows={1}
            required
            className="p-2 self-center min-h-[2.3rem] text-sm bg-white dark:bg-gray-800 border border-gray-200 shadow-sm rounded-md mr-2"
          />
          <button
            type="submit"
            className="align-middle bg-topo-navy hover:bg-blue-800 rounded focus:ring-blue-300 h-10 p-2 rotate-90"
          >
            <HeroIcon className="h-5 w-5 text-white" icon="PaperAirplaneIcon" />
          </button>
        </form>
      </div>

      {/* All Comments */}
      {patientComments.map((comment) => (
        <div key={comment.id} className="group">
          <div className="flex items-center space-x-2 mt-4 ">
            <div className="flex-shrink-0">
              <Avatar
                name={comment?.created_by?.name}
                className="h-8 w-8 rounded-full dark:border-2 dark:border-gray-700 cursor-default"
              />
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-semibold text-gray-900 truncate dark:text-white flex items-baseline">
                {comment.created_by?.name}
                <div className="ml-2 text-xs font-normal text-gray-500 truncate dark:text-gray-400">
                  {friendlyTimeDate(comment.created_at)}
                </div>
              </p>
              {comment.study?.abbreviation && (
                <StudyScreenStatusChip
                  text={comment.study?.abbreviation?.substring(0, 13)}
                />
              )}
            </div>
          </div>
          {comment.id !== updatingCommentId && (
            <div className="flex">
              <div className="w-5 flex flex-col pl-2">
                {/* <div> */}
                {comment.created_by_user_id === currentUser?.id && (
                  <button
                    type="button"
                    onClick={() => handleEdit(comment.id, comment.body)}
                    onKeyUp={() => handleEdit(comment.id, comment.body)}
                    className={`${
                      comment.id !== updatingCommentId ? "hidden" : ""
                    } mt-2 align-middle group-hover:inline-block text-black hover:text-gray-500`}
                  >
                    <HeroIcon className="h-4 w-4" icon="PencilAltIcon" />
                  </button>
                )}
                {/* </div> */}
                {/* <div> */}
                {/* </div> */}
              </div>

              <div className="w-64 pl-5 pt-1.5 text-sm min-h-[2rem]">
                {comment.body}
                {!dateCompare(comment.created_at, comment.updated_at) && (
                  <span className=" ml-2 text-xs font-normal text-gray-500 truncate dark:text-gray-400">
                    <span
                      className="cursor-default"
                      title={`Last updated: ${friendlyTimeDate(
                        comment.updated_at,
                      )}`}
                    >
                      (edited)
                    </span>
                  </span>
                )}
              </div>
            </div>
          )}
          {comment.id === updatingCommentId && (
            <div className="flex">
              <div className="pl-2 pt-8">
                <button
                  type="button"
                  onClick={() => handleDelete(comment.id)}
                  onKeyUp={() => handleDelete(comment.id)}
                  className={`${
                    comment.id !== updatingCommentId ? "hidden" : ""
                  } h-10 align-middle group-hover:inline-block text-black hover:text-red-500`}
                >
                  <HeroIcon className="h-4 w-4" icon="TrashIcon" />
                </button>
              </div>
              <form
                className="flex ml-4"
                onSubmit={handleSubmitUpdateForm(onUpdate)}
              >
                <input type="hidden" {...registerUpdateForm("id")} />
                <textarea
                  {...registerUpdateForm("body")}
                  rows={1}
                  className="p-2 self-center min-h-[4rem] text-sm bg-white border border-gray-200 shadow-sm rounded-md mr-2"
                />
                <div className="flex flex-col">
                  <button
                    type="submit"
                    className="align-middle p-2 text-black  hover:text-topo-green"
                  >
                    <HeroIcon className="h-5 w-5 " icon="CheckCircleIcon" />
                  </button>
                  <button
                    type="button"
                    onClick={() => setUpdatingCommentId("")}
                    onKeyUp={() => setUpdatingCommentId("")}
                    className="align-middle p-2 text-black hover:text-red-500"
                  >
                    <HeroIcon className="h-5 w-5 " icon="XCircleIcon" />
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      ))}
      {/* End Comments */}
    </div>
  )
}
