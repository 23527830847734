import { Avatar } from "../../components/avatar"
import { classNames } from "../../shared/class-names"
import { friendlyDateTime } from "../../shared/friendly-dates"

export function ChatBubble(props) {
  const { message, patient } = props
  const outbound = message.outbound
  return (
    <div>
      <div className="flex items-start gap-2.5">
        {/* <Avatar initials="BG" /> */}
        <div className="flex flex-col gap-0.25 w-full max-w-[320px]">
          <div
            className={classNames(
              outbound ? "justify-end" : "justify-start",
              "flex items-center space-x-2 rtl:space-x-reverse",
            )}
          >
            <div className="text-sm  font-semibold text-gray-900 dark:text-white">
              {outbound
                ? "Topography Health"
                : `${patient?.given_name} ${patient?.family_name}`}
            </div>
          </div>
          <div
            className={classNames(
              outbound ? "justify-end" : "justify-start",
              "flex items-center space-x-2 rtl:space-x-reverse mb-1",
            )}
          >
            <div className="text-xs font-normal text-gray-500 dark:text-gray-400">
              {friendlyDateTime(message.delivered_at)}
            </div>
          </div>
          <div
            className={classNames(
              outbound
                ? "bg-blue-500 text-white rounded-ss-xl rounded-es-xl rounded-ee-xl"
                : "bg-gray-100 text-gray-900  rounded-e-xl rounded-es-xl",
              "flex flex-col leading-1.5 p-4 border-gray-200 dark:bg-gray-700",
            )}
          >
            <p className="text-sm font-normal  ">{message.message}</p>
          </div>
          <div className="flex items-center justify-end pt-0.5">
            <span
              title={friendlyDateTime(
                message.read_at ? message.read_at : message.delivered_at,
              )}
              className="text-xs font-normal text-gray-500 dark:text-gray-400"
            >
              {message.read_at ? "Read" : "Delivered"}
            </span>
          </div>
        </div>
        {/* <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" data-dropdown-placement="bottom-start" className="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 dark:focus:ring-gray-600" type="button">
        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
           <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
        </svg>
     </button> */}
        {/* <div id="dropdownDots" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600">
        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconButton">
           <li>
              <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Reply</a>
           </li>
           <li>
              <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Forward</a>
           </li>
           <li>
              <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Copy</a>
           </li>
           <li>
              <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Report</a>
           </li>
           <li>
              <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete</a>
           </li>
        </ul>
     </div> */}
      </div>
    </div>
  )
}
