import {
  CalendarIcon,
  ClipboardIcon,
  PhoneIcon,
  SearchIcon,
} from "@heroicons/react/solid"
import React, { useState, useEffect } from "react"

const LoadingScreen = ({ isLoading }) => {
  const [activeIcon, setActiveIcon] = useState(0)
  const [dots, setDots] = useState("")

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setActiveIcon((prev) => (prev + 1) % 4)
        setDots((prev) => (prev.length < 3 ? `${prev}.` : ""))
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [isLoading])

  const icons = [
    { Icon: SearchIcon, key: "magnifying-glass" },
    { Icon: ClipboardIcon, key: "clipboard" },
    { Icon: PhoneIcon, key: "phone" },
    { Icon: CalendarIcon, key: "calendar" },
  ]

  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-opacity-100">
      <div className="flex space-x-8 m-20">
        {icons.map(({ Icon, key }, index) => (
          <Icon
            key={key}
            className={`w-16 h-16 text-blue-500 transition-all duration-300 ${
              activeIcon === index ? "scale-150" : "scale-100"
            }`}
          />
        ))}
      </div>
      <div className="text-center text-xl font-semibold text-blue-500">
        <span className="inline-block w-24 text-left">Loading{dots}</span>
      </div>
    </div>
    // </div>
  )
}

export default LoadingScreen
