import { HeroIcon } from "../hero-icon"
import { ScreenQualificationCriteriaTable } from "./screen-qualification-criteria-table"

interface Props {
  screenId: string
  criteria: [object]
  questions: [object]
  confidenceScore: string
}

export function ScreenQualificationCriteria(props) {
  const questionIsExclusion = (question_id) =>
    props.questions.some(
      (question) =>
        question.Question.id === question_id &&
        question.StudyQuestion?.meta?.categories?.includes(
          "exclusion_criteria",
        ),
    )

  return (
    <div className="mt-4 p-6 pt-4 rounded-lg border border-gray-200 shadow-md bg-white dark:bg-transparent">
      <div className="items-center min-h-5 mb-2">
        <div className="flex justify-between">
          <div className="flex items-center">
            <HeroIcon
              icon="ClipboardListIcon"
              className="text-gray-900 w-6 h-6 mr-1 dark:text-gray-400"
            />
            <h3 className="p-0 mr-2 text-xl font-bold text-gray-900 dark:text-white">
              Qualification Criteria
            </h3>
            {/* <SaveSpinner isShowing={isLoading} /> */}
          </div>
        </div>
        {props.confidenceScore && (
          <div className="mt-4">
            <div className="">
              <div className="flex">
                <div className="font-semibold mr-1 w-36 text-right">
                  Confidence Score:
                </div>
                {props.confidenceScore}
              </div>
            </div>
          </div>
        )}
        <div
          className={`mt-4 ${props?.criteria?.length > 0 ? "block" : "hidden"}`}
        >
          {props?.criteria?.length && (
            <ScreenQualificationCriteriaTable
              criteria={props?.criteria}
              questionIsExclusion={questionIsExclusion}
            />
          )}
        </div>
      </div>
    </div>
  )
}
